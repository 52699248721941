@tailwind base;
@tailwind components;
@tailwind utilities;
@import '~antd/dist/antd.css';

html,
body {
    max-width: 100%;
    overflow-x: hidden;
}

.text-small{
    font-size: 0.8em;
}